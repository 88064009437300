<script src="../../vue.config.js"></script>
<template>
  <div class="page">
    <NavBar>订单出票</NavBar>
    <div class="topBG"></div>
    <div class="frame">
      <div class="frame-title-l">{{ orderData.proName }}</div>
      <van-field readonly label="订单人数" :value="orderData.qtyPlayer+'人'"/>


      <van-field readonly clickable label="入园日期" :value="orderData.status === 1?visitedDate.text:orderData.playTime"
                 @click="orderData.status === 1?(showCalendar=true):null"/>
      <van-calendar v-model="showCalendar" @confirm="changeDate" color="#00b453"/>


      <van-field v-if="orderData.isZjjWlyTicket===1" readonly clickable label="入园地址"
                 :value="orderData.status === 1?visitedStation.text:orderData.stationName" placeholder="选择入园地址"
                 @click="orderData.status === 1?(showStation = true):null"/>
      <van-popup v-model="showStation" round position="bottom">
        <van-picker title="选择入园地址" show-toolbar :columns="stationList" value-key="text" @cancel="showStation = false"
                    @confirm="changeStation"/>
      </van-popup>


      <van-field v-if="orderData.isZjjWlyTicket===1" readonly clickable label="入园时间"
                 :value="orderData.status === 1?visitedTime.text:orderData.timeWindowName" placeholder="选择入园时间"
                 @click="orderData.status === 1?(showTime=true):null"/>
      <van-popup v-model="showTime" round position="bottom">
        <van-picker title="选择入园时间" show-toolbar :columns="timeList" value-key="text" @cancel="showTime = false"
                    @confirm="changeTime"/>
      </van-popup>


    </div>
    <div class="frame">
      <div class="frame-title-l">购票信息</div>
      <div class="playerItem" v-for="(player,index) in orderData.players" :key="player.userIdcard">
        <div class="playerTitle">* 游客{{ index + 1 }}</div>
        <van-field readonly label="姓名" v-model="player.userName" placeholder="请输入购票人姓名"/>
        <van-field readonly label="身份证" v-model="player.idcard" placeholder="请输入购票人身份证号码"/>
      </div>

      <!--      <div v-if="newPlayers">-->
      <div class="playerItem" v-for="(player,index) in newPlayers" :key="index">
        <div class="playerTitle">* 游客{{ index + orderData.players.length + 1 }}</div>
        <van-field :readonly="orderData.status !== 1" label="姓名" v-model="player.userName" placeholder="请输入购票人姓名"/>
        <van-field :readonly="orderData.status !== 1" label="身份证" v-model="player.userIdcard"
                   placeholder="请输入购票人身份证号码"/>
      </div>
      <!--      </div>-->
    </div>
    <div class="h300"></div>
    <div class="buttonBlock column-start-center" v-if="orderData.status === 1">
      <div class="confirmBtn">
        <van-button type="primary" :disabled="!orderIsOver" size="large" color="#00b453" @click="orderConfirm">确认出票
        </van-button>
      </div>
    </div>
    <div class="buttonBlock column-start-center" v-else>
      <div class="confirmBtn">
        <van-button type="primary" disabled size="large" color="#00b453" @click="orderEnd">{{ orderStatus[orderData.status] }}
        </van-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CompleteOrder",
  data() {
    return {
      banBtn: true,
      visitedDate: {text: "请选择游玩日期", isSelect: false},
      visitedStation: {text: "请选择入园站点", id: false},
      visitedTime: {text: "请选择入园时间", id: false},
      showCalendar: false,//控制入园时间选择框显示
      showStation: false,//控制入园地址选择框显示
      showTime: false,//控制入园时间选择框显示
      isDY0427: false,
      newPlayers: [],
      stationList: [{id: 1, text: "东门(吴家峪门票站)"},
        {id: 2, text: "南门(森林公园门票站)"},
        {id: 3, text: "中门(梓木岗门票站)"},
        {id: 4, text: "西门(杨家界门票站)"},
        {id: 5, text: "北门(天子山门票站)"}],
      timeList: [
        // {id: 6, text: "6:30-7:30"},
        {id: 7, text: "7:30-8:30"},
        {id: 8, text: "8:30-9:30"},
        {id: 9, text: "9:30-10:30"},
        {id: 10, text: "10:30-11:30"},
        {id: 11, text: "11:30-17:00"},],
      orderStatus:["","已下单","已出票","退票中","已退票"]
    };
  },
  computed: {
    orderData() {
      let ord = this.$store.state.orderData
      for (let j = 0; j < ord.players.length; j++) {
        ord.players[j].idcard = ord.players[j].userIdcard.slice(0, 6) + "********" + ord.players[j].userIdcard.slice(-4)
      }
      // console.log(ord)
      return ord
    },
    orderIsOver() {
      let newType = true
      if (this.newPlayers.length > 0) {

        for (let i = 0; i < this.newPlayers.length; i++) {
          if (this.newPlayers[i].userName === "") {
            newType = false
          } else if (this.newPlayers[i].userIdcard.length !== 18) {
            newType = false
          }
        }
        // console.log(newType)
      }
      return this.visitedDate.isSelect && (this.orderData.isZjjWlyTicket === 1 ? (this.visitedStation.id && this.visitedTime.id) : true) && newType
    },
  },
  watch: {
    orderData: {
      handler(value) {
        if (value.qtyPlayer > value.players.length && value.status === 1) {
          let newLength = value.qtyPlayer - value.players.length
          let newList = []
          for (let i = 0; i < newLength; i++) {
            newList.push({
              userName: "",
              userIdcard: ""
            })
          }
          this.newPlayers = newList
        }
        let startTime = new Date("2022/04/26 16:30:00").valueOf()
        let endTime = new Date("2022/04/28 01:30:00").valueOf()
        let formatCreatTime = value.createTime.replace(/\.|\-/g, '/')
        let creatTime = new Date(formatCreatTime).valueOf()
        this.isDY0427 = creatTime > startTime && creatTime < endTime
        // console.log("startTime:", startTime)
        // console.log("endTime:", endTime)
        // console.log("creatTime:", creatTime)
        // console.log("isDY0427:", this.isDY0427)
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      this.api.queryOrder().then(apiRes => {
        if (apiRes.success) {
          this.$store.commit('setOrderData', apiRes.data)
        }
      })
    },
    changeDate(value) {
      this.showCalendar = false
      this.visitedDate = {
        isSelect: true,
        text: value.getFullYear() + "-" + ((value.getMonth() + 1) > 9 ? (value.getMonth() + 1) : ("0" + (value.getMonth() + 1))) + "-" + (value.getDate() > 9 ? value.getDate() : ("0" + value.getDate()))
      }
    },
    changeStation(value) {
      this.showStation = false
      this.visitedStation = value
    },
    changeTime(value) {
      this.showTime = false
      this.visitedTime = value
    },
    orderConfirm() {
      if (this.banBtn) {
        this.banBtn = false
        let that = this
        let players = []
        players = players.concat(this.orderData.players)
        players = players.concat(this.newPlayers)
        // console.log("op", this.orderData.players)
        // console.log("np", this.newPlayers)
        // console.log("players", players)
        let formData = {
          sign: this.$store.state.sign.sign,
          orderNum: this.$store.state.sign.orderNum,
          play_time: this.visitedDate.text,
          players: players
        }
        if (this.orderData.isZjjWlyTicket === 1) {
          formData.stationId = this.visitedStation.id
          formData.timeWindowId = this.visitedTime.id
        }
        this.api.createOrder(formData).then(apiRes => {
          this.banBtn = true
          if (apiRes.success) {
            that.$toast.success("预约成功！");
            that.getOrder()
          } else {
            that.$toast.fail(apiRes.msg)
          }
        })
      }
    },
    orderEnd() {
      this.$toast.success("已出票完成！请勿重复操作")
    }
  }
}
</script>

<style scoped>
.topBG {
  position: fixed;
  top: 2.2rem;
  left: 0;
  right: 0;
  height: 16rem;
  background: linear-gradient(to bottom, #00b453, #F2F2F2);
  z-index: -1;
}

.frame {
  width: 15rem;
  margin: .5rem .5rem 0 .5rem;
  padding: .5rem 0;
  background-color: #FFFFFF;
  border-radius: .5rem;
  z-index: 99;
}

.frame-title-l {
  padding: .2rem .5rem;
  font-size: .7rem;
  font-weight: bold;
  text-align: left;
}

.frame-title-s {
  padding: .2rem .5rem;
  font-size: .5rem;
  font-weight: bold;
  text-align: left;
}

.playerItem {
  margin: .5rem .5rem 0 .5rem;
  border-bottom: solid 1px #EEEEEE;
}

.playerTitle {
  margin-left: 1rem;
  font-size: .5rem;
  font-weight: bold;
  color: #00b453;
}

.playerTitle {
  margin-left: 1rem;
  font-size: .5rem;
  font-weight: bold;
  color: #00b453;
}

.buttonBlock {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
}

.confirmBtn {
  width: 5rem;
  box-shadow: 0 0 .8rem #AAAAAA;
}
</style>
